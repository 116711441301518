<template>
  <div id="popup" class="overlay">
    <div class="w-11/12 md:w-2/4 popup" v-if="!showConfirmation">
      <div class="flex justify-between items-center">
        <h5 class="text-xl font-semibold">Create Location</h5>
        <a @click="changeShowConfirmation()" class="text-2xl font-bold" href="#"
          >&times;</a
        >
      </div>
      <hr class="mt-2" />
      <form @submit.prevent="handleSubmit" class="mt-8">
        <div class="form__div">
          <input
            class="form__input"
            type="text"
            placeholder=" "
            v-model="name"
            required
          />
            <label for="" class="form__label">Name</label>
        </div>
        <div class="form__div">
          <input
            class="form__input"
            type="text"
            placeholder=" "
            v-model="facilityCode"
            required
          />
            <label for="" class="form__label">Organization code</label>
        </div>
        <p v-if="showError" class="text-center text-red-600">
          {{errorText}}
        </p>
        <div class="px-3 my-4 flex flex-row-reverse">
          <button
            id="b-create"
            type="submit"
            :disabled="processingCreation"
            class="px-4 py-2 create-btn bg-mindaro text-darkBlue ml-12"
          >
            <em
              v-if="processingCreation"
              class="fa fa-spinner fa-spin mr-2"
            ></em
            >Create
          </button>
          <a 
            type="button" 
            @click="changeShowConfirmation()" 
            class="px-4 py-2 text-white bg-darkBlue" href="#"
          >
            Cancel
          </a>
        </div>
      </form>
    </div>
    <div class="popup w-11/12 md:w-1/2" v-if="showConfirmation">
      <div class="flex justify-between">
        <h5 class="text-lg md:text-xl font-semibold">
          LOCATION HAS BEEN SUCCESSFULLY CREATED
        </h5>
        <a
          @click="changeShowConfirmation()"
          class="text-base md:text-2xl font-bold"
          href="#"
          >&times;</a
        >
      </div>
      <em
        class="block mx-auto mt-5 mb-14 text-9xl fa-solid fa-circle-check check-icon"
      ></em>
      <p class="mb-5 text-center">Location has been successfully added.</p>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { createLocation } from "@/services/location/location.js";

const showConfirmation = ref(false);
const showError = ref(false);
const errorText = ref(null);
const name = ref(null);
const facilityCode = ref(null);
const processingCreation = ref(false);

const emit = defineEmits(["addLocation"]);

async function handleSubmit() {
  processingCreation.value = true
  const response = await createLocation(name.value, facilityCode.value);

  if (!response.success) {
    showError.value = true;
    if (response.data?.name) {
      errorText.value = response.data.name[0];
    } else if (response.data?.facility_code) {
      errorText.value = response.data.facility_code.facility_code;
    } else {
      errorText.value = "An error has ocurred, please try again!";
    }
    processingCreation.value = false
    return;
  }

  showConfirmation.value = true;
  emit("addLocation", name.value);
  processingCreation.value = false
}

function changeShowConfirmation() {
  showError.value = false;
  errorText.value = null;
  processingCreation.value = false
  showConfirmation.value = false;
}

</script>

<style scoped>
#b-create:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 200ms;
  visibility: hidden;
  opacity: 0;
  z-index: 99;
}

.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 75px auto;
  padding: 20px;
  background: #fff;
  border: 1px solid #666;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
  position: relative;
}

.check-icon {
  height: 87px;
  width: 87px;
}
</style>
